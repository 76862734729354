<!--  -->
<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="table">
    <el-table class="common-table" :data="tableData">
      <el-table-column label="报表名称">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => routeFun(`/custom-report/report-detail/${scope.row.id}?reportName=${scope.row.recordName}&elementUuid=${scope.row.elementUuid}&recordType=${scope.row.recordType}`)" >{{ scope.row.recordName || "--" }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="报表类型">
        <template slot-scope="scope">
          <div v-if="scope.row.recordType == '1'">公司报表</div>
          <div v-else-if="scope.row.recordType == '2'">销售人员报表</div>
          <div v-else-if="scope.row.recordType == '3'">质检人员报表</div>
          <div v-else-if="scope.row.recordType == '4'">考勤人员报表</div>
          <div v-else-if="scope.row.recordType == '5'">客户数据报表</div>
          <div v-else-if="scope.row.recordType == '6'">店铺数据报表</div>
          <div v-else-if="scope.row.recordType == '7'">客服数据报表</div>
          <div v-else-if="scope.row.recordType == '8'">店铺服务数据</div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="报表说明">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="scope.row.remark" placement="right">
            <div style="white-space: nowrap;white-space: nowrap;text-overflow: ellipsis;overflow: hidden">{{ scope.row.remark }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="createName" label="创建人"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center;">
            <img v-if="typeName == 'MyReport'" v-show="scope.$index == 0" class="colImg noClick" src="~@/assets/images/up0.png"/>
            <img v-if="typeName == 'MyReport'" @click="() => {reportSort(scope.row, -1)} " v-show="scope.$index" class="colImg" src="~@/assets/images/up.png" />
            <img v-if="typeName == 'MyReport'" v-show="tableData.length - (scope.$index + 1) == 0" class="colImg2 noClick" src="~@/assets/images/down0.png" />
            <img v-if="typeName == 'MyReport'" @click="() => {reportSort(scope.row, 1)}" v-show="tableData.length - (scope.$index + 1) != 0" class="colImg2"  src="~@/assets/images/down.png" />
            <el-button @click=" () => { changeDialogTit(scope.row) }" type="text" >编辑</el-button>
            <el-button @click="() => { routeFun(`/custom-report/report-setting/${scope.row.id}?elementUuid=${scope.row.elementUuid}&reportName=${scope.row.recordName}&recordType=${scope.row.recordType}`) }" type="text">设置报表</el-button>
            <!-- <el-popconfirm title="确定删除吗？"> -->
              <el-button slot="reference" type="text" @click="() => deleteShow(scope.row.id)">删除</el-button>
            <!-- </el-popconfirm> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="typeName == 'MyReport'" label="是否公开">
        <template slot-scope="scope">
          <el-switch @change="(val) => { reportIsCommon(scope.row, val) }" v-model="scope.row.publicFlag" :active-value="1" :inactive-value="0" active-color="#1890FF" inactive-color="#DBDBDB"></el-switch>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog v-loading="loading" element-loading-text="数据较多，拼命加载中..." v-dialogDrag title="删除报表" class="common-dialog-isOk" :append-to-body="true" width="400px" :visible.sync="deleteVisible">
      <div>
        确认删除该报表吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="deleteVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => {reportDelete()}">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { tableColumn, Config } from "../../../utils/index.js";
import { reportList, reportAddEdit, reportDelete, reportSort } from "../../../service/report.js"

export default {
  props: [ "typeName"],
  data() {
    return {
      loading: false,
      tableColumn,
      adminId:  this.$sto.get(Config.constants.userInfo).user_id,
      companyId: this.$sto.get(Config.constants.userInfo).company_id,
      tableData: [],
      params: {
        page: 1,
        pageSize: 10
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      deleteVisible: false,
      deleteId: '',
    };
  },
  mounted() {
    this.reportList()
  },
  methods: {
    async reportDelete() { // 删除自定义报表
      this.loading = true
      let { code } = await reportDelete({id: this.deleteId})
      this.loading = false
      if (code == 200) {
        this.$message.success('删除成功')
        this.reportList()
        this.deleteVisible = false
      } 
    },
    deleteShow(id) {
      this.deleteId = id
      this.deleteVisible = true
    },
    async reportIsCommon (row, val) { // 是否公开
      let params = {...row}
      params.publicFlag = val
      this.loading = true
      let { code } = await reportAddEdit(params)
      this.loading = false
      if (code == 200) {
        this.$message.success("操作成功")
        this.reportList()
      }
    },
    pageFun(e, type) { // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.reportList();
    },
    resetBtn() { // 重置
      this.params = {
        page: 1,
        pageSize: 10,
      };
      this.reportList();
    },
    async reportList() { // 自定报表-列表
      let params = {...this.params}
      if ( this.typeName == 'MyReport' ) {
        params.adminId = this.adminId 
        delete params.companyId
      } else {
        params.companyId = this.companyId
        delete params.adminId
      }
      this.loading = true
      let { data } = await reportList(params)
      this.loading = false
      this.tableData = data.list
      this.pagination.total = data.total;
    },
    async reportSort(row, type) { // 上下移按钮
      this.loading = true
      let { code } = await reportSort({id: row.id, type: type})
      this.loading = false
      if( code == 200 ) {
        this.$message.success("排序成功")
        this.reportList()
      }
      // let tempSort = this.tableData[index - 1].sort;
      // this.$set(this.tableData[index - 1], 'sort', this.tableData[index].sort);
      // this.$set(this.tableData[index], 'sort', tempSort);
    },
    // async sortDown(index, row) { // 下移按钮
    //   let downRow = {...this.tableData[index + 1]}
    //   let newSort = row.sort
    //   row.sort = downRow.sort
    //   downRow.sort = newSort
    //   this.loading = true
    //   await reportAddEdit(downRow)
    //   let { code } = await reportAddEdit(row)
    //   this.loading = false
    //   if( code == 200 ) {
    //     this.$message.success("排序成功")
    //     this.reportList()
    //   }
    //   // let tempSort = this.tableData[index + 1].sort;
    //   // this.$set(this.tableData[index + 1], 'sort', this.tableData[index].sort);
    //   // this.$set(this.tableData[index], 'sort', tempSort);
    // },
    routeFun(url) {
      this.$router.push(url)
    },
    changeDialogTit(info) {
      this.$emit("changeDialogTit", info);
    }
  },
};
</script>

<style lang="less" scoped>
.table {
  .colImg {
    margin-right: 8px;
    cursor: pointer;
  }
  .colImg2 {
    margin-right: 26px;
    cursor: pointer;
  }
  .noClick {
    cursor: auto;
  }
  /deep/.el-popover__reference-wrapper{
    margin-left: 10px;
  }
}
</style>
